body {
  margin: 0px;
  padding: 0px;
}

/* 
Stole of TOBIAS AHLIN
http://tobiasahlin.com/spinkit/ 
*/
.spinner {
    width: 40px;
    height: 40px;
  
    position: relative;
    margin: 100px auto;
  }
  
  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #8D0AFC;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }

  .year {
      display: flex;
      flex-direction: row;
      margin: 8px 0;
      justify-content: flex-start;
      align-items: center;
  }
    .year b {
      min-width: 100px;
    }
    .project-item {
        display: block;
        margin: 4px; 
        width: 0px;
        height: 0px;
        border: 16px solid transparent;
        background-color:rgba( 0, 0, 0, 0.05);
        position: relative;
        border-radius: 100%;
        margin-right: 1vw;
    }

    .uh.active .project-item,
    .uh.active .description {
      opacity: 0.2;
    }
    .uh.active .project-item.active,
    .uh.active .active .project-item,
    .uh.active .active .description {
      opacity: 1;
    }

    .project-item.item-0 { border-left-color: #fff; }
    .project-item.item-1 { border-top-color: #2397FC; }
    .project-item.item-2 { border-right-color: #FC150A; }
    .project-item.item-3 { border-bottom-color: #30FC7C; }
    
    
    .roles {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 80%;
      margin-bottom: 4vh;
    }
      .role {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-right: 4vw;
        cursor: pointer; 
      }
        .role.active {
          opacity:1;
        }

  .uppdragshistorik.active .uppdrag {
    opacity: 0.1;
  }
    .uppdragshistorik.active .uppdrag.active {
      opacity: 1;
    }