/*
orange #E37B40
turq #46B29D
red #cd254a
dark blue #324D5C
yellow #F0CA4D
*/

html, .root {
    font-family: 'Arvo', Helvetica, Arial, sans-serif;
    font-size: 1.8vw;
    line-height: 1.6;
    color: #1d1d1d;
  }
  body {
    background-color: #fdd418;
  }
  .lightText {
    color: #BD9D11;
  }
  .article {
    max-width: 560px;
    margin: auto;
    color: #6E21B0;
  }

  .uppdrag {
    background-image:url("./resources/line.svg");
    background-clip: border-box;
    background-repeat: no-repeat;
    background-size: 8vw;
    padding: 0 0 4vw;
    background-position: center bottom;
    margin: 4vw 0 8vw;
  }

  h1, h2, h3, h4, h5 {
    /* font-family: 'PT Sans', Helvetica, Arial, sans-serif; */
    color: #8D0AFC;
    font-family: rig-solid-bold-halftone, sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  h4, h5 {
    color: #7D680B;
  }

  h1, .h1 {
    font-size: 6.2vw;
    line-height: 1.1;
    margin-top: .8vw;
    margin-bottom: .8vw;
  }
  h2, .h2 {
    font-size: 2.4vw;
    line-height: 1.2;
    margin: .8vw 0;
  }
  h3, .h3 {
    font-size: 2.9vw;
    line-height: 1.8;
    margin-top: 2.6vw;
    margin-bottom: 0px;
  }
  h4, .h4 {
    font-size: 1.8vw;
    line-height: 1.5;
    margin-top: 2.6vh;
    margin-bottom: 0px;
    letter-spacing: 250%;
  }
  h5, .h5 {
    font-size: 1.8vw;
    line-height: 1.5;
    margin-top: 2.6vh;
    margin-bottom: 0px;
  }
  p, ul, ol, pre, table, blockquote {
    margin-top: 0px;
    margin-bottom: 2.6vh;
  }
  ul ul, ol ol, ul ol, ol ul {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  ul {
      margin: 0 1vw;
  }
  ul li {
      margin: 1vh 0;
  }
  
  /* Let's make sure all's aligned */
  hr, .hr {
    border: 1px solid;
    margin: -1px 0;

  }
  a, a:link ,a:visited,
  i, strong, em, small, code {
    line-height: 0;
    color: #7D680B
  }
    a:hover, a:active {
        color: #BD9D11;
    }
  sub, sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }

  section {
    margin: 15vh 0;
    position: relative;
  }
  .uppdragshistorik section {
    margin: 1vh 0;
  }

.TheContainer {
  margin: 10vh 20vw;
  padding: 4vh 6vw;
  width: 60vw;
  min-height:80vh;
  border-left: .1vw solid #BD9D11;
  box-sizing: border-box;
}

#logo {
    background-image: url("./resources/pot.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 19vh;
    flex: 1 1 100%;
    position: fixed;
    top: 100px;
    left: 1vw;
    z-index: 100;
    width: 19vw;
}

#face {
    background-image: url("./resources/frank.jpg");
    background-clip: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    border: .5vw double #fff;
    border-radius: 100%;
    box-sizing: content-box;
    padding-bottom: 50%;
    justify-content: stretch;
    flex: 1 1;
    align-self: center; 
}

.huvud {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.me {
    flex: 1 1;
    align-self: center;
}

.intro {
    margin: 10vw 0 3vw;
}

.dateRange {
    white-space: nowrap;
    display:block;
}

.company, .company-number {
  display: block;
  font-size: 80%;
}

@media only screen and (max-width: 600px) {
  html, .root {
    font-size: 16px;
    line-height: 1.6;
  }

  h1, .h1 {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h2, .h2 {
    font-size: 24px;
    margin-top: 8px;
    margin-bottom: 10px;
  }
  h3, .h3 {
    font-size: 24px;
  }
  h4, .h4 {
    font-size: 18px;
  }
  h5, .h5 {
    font-size: 18px;
  }
  .TheContainer {
    margin: 0;
    padding: 2vh 10vw;
    border: none;
    width: auto;
  }

  #logo { 
    display: block;
    height: 25vh;
    position: relative;
    width: 100%;
    top: auto;
    left: auto;
  }
  .me { 
    width: 100%;
    margin: 10vw 0;
  }
  #face { padding-bottom: 50%;}
  .huvud { flex-direction: row; }
}